<template>
    <div>
        <div class="tool">
            <el-button type="primary" class="el-icon-plus" style="margin-right:5px" @click="AddClick"> 新增</el-button>
            <el-input placeholder="小区名称" style="width:200px;margin-right:20px" v-model="name">
                 <el-button slot="append" icon="el-icon-search" @click="Search"></el-button>
            </el-input>
        </div>

        <el-table :data='VillageDataList' border style="width: 100%;font-size:13px" stripe :highlight-current-row='true'>
            <el-table-column align="center" prop="vg_name" label="小区名称"></el-table-column>
            <el-table-column align="center" prop="vg_province" label="省市">
                <template slot-scope="scope">
                    {{getcity(scope.row)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="vg_addr" label="地址"></el-table-column>
            <!-- <el-table-column align="center" prop="vg_lng" label="地图标记">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.vg_lng" type="success" disable-transitions class="el-icon-check"></el-tag>
                    <el-tag v-else type="danger" disable-transitions class="el-icon-close"></el-tag>
                </template>
            </el-table-column> -->
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <!-- <el-button type="primary"  size="small" class="el-icon-position" @click="AddMap(scope.row)"> </el-button> -->
                    <el-button @click="EditClick(scope.row)" type="primary" size="small" icon="el-icon-edit"></el-button>
                    <el-button @click="DelClick(scope.row)" type="danger" size="small" icon="el-icon-delete"></el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--弹框-->
        <el-dialog :title="title" :visible.sync="dialogVisible" width='30%' :append-to-body='true'>
            <el-form :model="vgForm" :rules="rules" ref="vgForm" label-width="100px" size='medium'>
                <el-form-item label="名称" prop="vg_name">
                    <el-input v-model="vgForm.vg_name"></el-input>
                </el-form-item>
                <el-form-item label="省">
                    <el-col :xs="23" :sm="12" :md="12" :lg="12">
                      <el-select v-model="vgForm.vg_province" style="width:100%;">
                        <el-option v-for="item in ProvinceDataList" :key="item.Id" :label="item.province" :value="item.Id"></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="1"><span>&nbsp;</span></el-col>
                    <el-col :xs="23" :sm="12" :md="12" :lg="11">
                      <el-select v-model="vgForm.vg_city" style="width:100%;">
                        <el-option v-for="item in cityDataList" :key="item.Id" :label="item.Name" :value="item.Id"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="vgForm.vg_addr"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import util from '../../util/util'

export default {
  data: function () {
    return {
      name: '',
      ProvinceDataList: [],
      cityDataList: [],
      VillageDataList: [],
      title: '',
      dialogVisible: false,
      vgForm: {
        vg_id: 0,
        vg_province: 0,
        vg_city: 0,
        vg_name: '',
        vg_addr: '',
        vg_lng: 0,
        vg_lat: 0
      },
      rules: {
        vg_name: [{ required: true, message: '请输入小区名称', trigger: 'blur' }]
      }
    }
  },
  computed: {
    getcity () {
      return function (row) {
        var t = ''
        this.ProvinceDataList.forEach(a => {
          if (a.Id === row.vg_province) {
            t = a.province
            a.cities.forEach(b => {
              if (b.Id === row.vg_city) {
                t += b.Name
              }
            })
          }
        })
        return t
      }
    }
  },
  created () {
  },
  mounted () {
    this.GetDataList()
  },
  methods: {
    GetDataList () {
      util.Post('village/getvillagedatalist', this.name).then(res => {
        if (res.rpStatus === 10006) {
          console.log(res.list)
          this.VillageDataList = res.list
        }
      })
    },
    Search () {
      this.GetDataList()
    },
    AddClick () {
      this.title = '新增'
      this.dialogVisible = true
      this.vgForm.vg_id = 0
      this.vgForm.vg_province = 801
      this.vgForm.vg_city = 802
      this.vgForm.vg_name = ''
      this.vgForm.vg_addr = ''
      this.vgForm.vg_lng = 0
      this.vgForm.vg_lat = 0
    },
    EditClick (row) {
      this.title = '修改'
      this.dialogVisible = true
      this.vgForm = JSON.parse(JSON.stringify(row))
    },
    submit () {
      this.$refs.vgForm.validate((valid) => {
        if (valid) {
          util.Post('village/edit', JSON.stringify(this.vgForm)).then(res => {
            if (res.rpStatus === 10006) {
              this.GetDataList()
              this.dialogVisible = false
            } else { this.$message.error(res.rpMsg) }
          })
        }
      })
    },
    DelClick (row) {
      this.$confirm('确定删除小区“' + row.vg_name + '”？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        util.Post('village/del', JSON.stringify(row)).then(res => {
          if (res.rpStatus === 10006) { this.GetDataList() } else { this.$message.error(res.rpStatus) }
        })
      }).catch(e => e)
    }
  },
  watch: {
    'vgForm.vg_province': function (newId) {
      this.cityDataList = []
      this.ProvinceDataList.forEach(a => {
        if (a.Id === newId) {
          this.cityDataList = a.cities
          if (this.vgForm.vg_id === 0) { this.vgForm.vg_city = a.cities[0].Id }
        }
      })
    }
  }
}
</script>

<style scoped>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tool{
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
}
.el-table{
    margin-top: 15px;
}
</style>
